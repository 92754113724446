<template>
  <v-card :elevation="6" class="mx-auto pa-6 mr-2" >
    <grafico-pizza
      :id="id"
      v-if="loading"
      :category-field="categoria"
      :value-fields="valueFields"
      :dados="dados"
      :title="title"
    />
  </v-card>
</template>
<script>

import { convertFloatToMoney } from '@/produto/common/functions/helpers';
import GraficoPizza from '../../graficos/GraficoPizza';

export default {
  name: 'GraficoPizzaDoacaoPorPlanta',
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    filtro: {
      type: Object,
    },
    categoria: {
      type: String,
    },
  },
  watch: {
    filtro: {
      handler() {
        this.filtroAplicado = this.filtro || {};
        this.buscarDados();
      },
    },
  },
  components: {
    GraficoPizza,
  },
  data() {
    return {
      akzoDashboardSustentabilidadeResource: this.$api.akzoDashboardSustentabilidade(this.$resource),

      dados: [],
      valueFields: [],
      qtdColunasLegenda: 0,
      loading: false,
      filtroAplicado: this.filtro || {},
    };
  },
  methods: {
    buscarDados() {
      const { idAnoPeriodo } = this.filtroAplicado;
      this.akzoDashboardSustentabilidadeResource.buscarDoacoesPorPlanta({ idAnoPeriodo })
        .then((response) => {
          this.dados = response.data.dados;
          this.ajustaDados(this.dados);

          this.valueFields = {
            valueField: this.categoria,
            titleField: 'planta',
            outlineAlpha: 0.4,
            depth3D: 15,
            balloonText: this.getBalloonText(),
            angle: 30,
            precision: 2,
            decimalSeparator: ',',
            thousandsSeparator: '.',
            labelText: '[[percents]]%',
          };
          this.loading = true;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    ajustaDados(dados) {
      dados.map((item) => {
        item.valor_formatado = this.getMoney(item.valor);
        return item;
      });
    },
    getMoney(str) {
      str = convertFloatToMoney(String(str));
      str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const money = str.split('.');
      return `${money[0].replace(/,/g, '.')},${money[1]}`;
    },
    getBalloonText() {
      const text = this.categoria === 'volume'
        ? 'Volume: <span style="font-size:14px"><b>[[value]]</b> ([[percents]]%)</span><br>'
        : 'Valor: <span style="font-size:14px"><b>R$ [[valor_formatado]]</b>';
      return `<span style='font-size:14px'><b>[[title]]</b></span><br> ${text}`;
    },
  },
  mounted() {
    this.buscarDados();
  },
};
</script>

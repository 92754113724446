<template>
  <v-container fluid grid-list-md>
    <v-row class='mx-3 mt-3'>
      <v-select style="max-width:600px"
        :items="listaPeriodos"
        return-object
        v-model="filtro"
        item-text="nome"
        item-value="idAnoPeriodo"
        :label="`${$tc('label.periodo', 1)} `"
        :no-data-text="$tc('message.nenhum_registro', 1)"
        clearable
        chips deletable-chips
        >
      </v-select>
    </v-row>
    <v-row>
      <v-col cols="6">
        <grafico-pizza-status-doacoes :filtro="filtro" />
      </v-col>

      <v-col cols="6">
          <grafico-pizza-doacoes-por-tipo :filtro="filtro" />
      </v-col>

      <v-col cols="6">
          <grafico-pizza-doacao-por-planta :filtro="filtro" id="grafico-volume-planta" :title="$tc('label.dash_volume_doado_planta')" categoria="volume" />
      </v-col>

      <v-col cols="6">
          <grafico-pizza-doacao-por-planta :filtro="filtro" id="grafico-doado-planta" :title="$tc('label.dash_valor_doado_planta')" categoria="valor" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import GraficoPizzaStatusDoacoes from './graficos-pizza-sustentabilidade/GraficoPizzaStatusDoacoes';
import GraficoPizzaDoacoesPorTipo from './graficos-pizza-sustentabilidade/GraficoPizzaDoacoesPorTipo';
import GraficoPizzaDoacaoPorPlanta from './graficos-pizza-sustentabilidade/GraficoPizzaDoacaoPorPlanta';

export default {
  name: 'DashboardPizzaPainelSustentabilidade',

  components: {
    MetadadosContainerLayout,
    GraficoPizzaStatusDoacoes,
    GraficoPizzaDoacoesPorTipo,
    GraficoPizzaDoacaoPorPlanta,
  },
  data() {
    return {
      akzoAnoFiscalResource: this.$api.akzoAnoFiscal(this.$resource),
      akzoDashboardSustentabilidadeResource: this.$api.akzoDashboardSustentabilidade(this.$resource),

      dados: [],
      loading: false,
      ordenacaoCampos: [
        'periodo',
      ],
      filtrosPainelSustentabilidade: {
        periodo: {
          id: null,
          nome: this.$tc('label.ver_todos', 1),
        },
      },
      filtro: null,
      listaPeriodos: [],
    };
  },
  methods: {
    buscarAnoFiscal() {
      this.akzoAnoFiscalResource.buscar({}, this.$resource)
        .then((response) => {
          this.listaPeriodos = response.data;

          if (this.listaPeriodos.length > 0) {
            const item = this.listaPeriodos[0];
            this.filtro = item;
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.buscarAnoFiscal();
  },
};
</script>
<style>
  .PainelSustentabilidade {
    margin-bottom: 2em;
  }
</style>

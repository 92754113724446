<template>
    <v-container fluid grid-list-md>
      <v-row class='mx-3'>
        <v-select style="max-width:600px"
          :items="listaPeriodos"
          return-object
           v-model="filtro"
          item-text="nome"
          item-value="id"
          :label="`${$tc('label.periodo', 1)} `"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          >
        </v-select>
      </v-row>
      <v-row>
        <v-col cols="12">
          <grafico-barras-previsto-liquidado
          :filtro="filtro">
          </grafico-barras-previsto-liquidado>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <grafico-barras-projetos-doacoes-avulsas
          :filtro="filtro">
          </grafico-barras-projetos-doacoes-avulsas>
        </v-col>
      </v-row>
      <v-row>
       <v-col cols="12">
        <grafico-barras-orcamento-por-oi
         :filtro="filtro">
        </grafico-barras-orcamento-por-oi>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <grafico-barras-investido-por-oi
          :filtro="filtro">
          </grafico-barras-investido-por-oi>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>

import GraficoBarrasPrevistoLiquidado from './graficos-barras-sustentabilidade/GraficoBarrasPrevistoLiquidado';
import GraficoBarrasProjetosDoacoesAvulsas from './graficos-barras-sustentabilidade/GraficoBarrasProjetosDoacoesAvulsas';
import GraficoBarrasOrcamentoPorOi from './graficos-barras-sustentabilidade/GraficoBarrasOrcamentoPorOi';
import GraficoBarrasInvestidoPorOi from './graficos-barras-sustentabilidade/GraficoBarrasInvestidoPorOi';

export default {
  name: 'DashboardBarraPainelSustentabilidade',
  computed: {

  },
  components: {
    GraficoBarrasPrevistoLiquidado,
    GraficoBarrasProjetosDoacoesAvulsas,
    GraficoBarrasOrcamentoPorOi,
    GraficoBarrasInvestidoPorOi,
  },
  data() {
    return {
      akzoDashboardResource: this.$api.akzoDashboardSustentabilidade(this.$resource),
      loading: false,
      filtro: null,
      listaPeriodos: [],
    };
  },
  methods: {
    buscarPeridosGraficoBarras() {
      this.akzoDashboardResource.buscarPeriodoAnoFiscalGraficoBarras()
        .then((response) => {
          this.listaPeriodos = response.data;
          if (this.listaPeriodos.length > 0) {
            const item = this.listaPeriodos[0];
            this.filtro = item;
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  beforeMount() {
    this.buscarPeridosGraficoBarras();
  },
};
</script>
<style>
  .PainelSustentabilidade__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
</style>

<template>
   <v-card :elevation="6" class="mx-auto pa-6 ml-2" >
    <grafico-pizza
      id="grafico-status-doacoes"
      :category-field="`status`"
      :value-fields="valueFields"
      :dados="dados"
      :title="$tc('label.dash_status_doacoes_brasil', 1)"
    />
  </v-card>
</template>
<script>

import { convertFloatToMoney } from '@/produto/common/functions/helpers';
import GraficoPizza from '../../graficos/GraficoPizza';

export default {
  name: 'GraficoPizzaStatusDoacoes',
  props: {
    filtro: {
      type: Object,
    },
  },
  watch: {
    filtro: {
      handler() {
        this.filtroAplicado = this.filtro || {};
        this.buscarDados();
      },
      deep: true,
    },
  },
  components: {
    GraficoPizza,
  },
  data() {
    return {
      akzoDashboardSustentabilidadeResource: this.$api.akzoDashboardSustentabilidade(this.$resource),

      dados: [],
      valueFields: {},
      filtroAplicado: this.filtro || {},
    };
  },
  methods: {
    buscarDados() {
      const { idAnoPeriodo } = this.filtroAplicado;
      this.akzoDashboardSustentabilidadeResource.buscarStatusDoacoes({ idAnoPeriodo })
        .then((response) => {
          this.dados = response.data.dados;
          this.ajustaDados(this.dados);

          this.valueFields = {
            valueField: 'volume',
            titleField: 'status',
            outlineAlpha: 0.4,
            depth3D: 15,
            balloonText: `
              <span style='font-size:14px'><b>[[title]]</b></span><br>
              Volume: <span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span><br>
              Valor: <span style='font-size:14px'><b>R$ [[valor]]</b>
            `,
            angle: 30,
            precision: 2,
            decimalSeparator: ',',
            thousandsSeparator: '.',
            colorField: 'color',
            labelText: '[[percents]]%',
          };
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    ajustaDados(dados) {
      dados.map((item) => {
        item.color = this.getColor(item.mnemonico);
        item.valor = this.getMoney(item.valor);
        return item;
      });
    },
    getColor(status) {
      if (status === 'EM_ANDAMENTO') {
        return '#f1c40f'; // amarelo;
      } if (status === 'CONCLUIDO') {
        return '#1abc9c'; // verde;
      } if (status === 'CANCELADO') {
        return '#e67e22'; // laranja;
      } if (status === 'REPROVADO') {
        return '#e74c3c'; // vermelho;
      } if (status === 'VENCIDO') {
        return '#7e57c2'; // roxo;
      } if (status === 'NAO_INICIADO') {
        return '#95a5a6'; // cinza;
      }

      // status === 'EM_ANALISE'
      return '#f8c82e'; // amarelo escuro
    },
    getMoney(str) {
      str = convertFloatToMoney(String(str));
      str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const money = str.split('.');
      return `${money[0].replace(/,/g, '.')},${money[1]}`;
    },
  },
};
</script>

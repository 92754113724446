<template>
  <v-card :elevation="6" class="mx-auto pa-6 mr-2" >
    <grafico-pizza
      id="grafico-doacoes-tipo"
      v-if="loading"
      :category-field="`descricao`"
      :value-fields="valueFields"
      :dados="dados"
      :title="$tc('label.dash_doacoes_por_tipo', 1)"
    />
  </v-card>
</template>
<script>

import { convertFloatToMoney } from '@/produto/common/functions/helpers';
import GraficoPizza from '../../graficos/GraficoPizza';

export default {
  name: 'GraficoPizzaDoacoesPorTipo',
  props: {
    filtro: {
      type: Object,
    },
  },
  watch: {
    filtro: {
      handler() {
        this.filtroAplicado = this.filtro || {};
        this.buscarDados();
      },
    },
  },
  computed: {
    filtrosFixosClass() {
    },
    isDadosGrafico() {
      return this.dados && this.dados.length > 0;
    },
  },
  components: {
    GraficoPizza,
  },
  data() {
    return {
      akzoDashboardSustentabilidadeResource: this.$api.akzoDashboardSustentabilidade(this.$resource),

      dados: [],
      valueFields: [],
      listValueFields: [
        'valor',
        'volume',
      ],
      qtdColunasLegenda: 0,
      loading: false,
      filtroAplicado: this.filtro || {},
      colors: ['#f1c40f', '#1abc9c', '#e67e22', '#e74c3c', '#7e57c2', '#95a5a6', '#4285f4', '#0b5394'],
    };
  },
  methods: {
    buscarDados() {
      const { idAnoPeriodo } = this.filtroAplicado;
      this.akzoDashboardSustentabilidadeResource.buscarDoacoesPorTipo({ idAnoPeriodo })
        .then((response) => {
          this.dados = response.data.dados;
          this.ajustaDados(this.dados);

          this.valueFields = {
            valueField: 'volume',
            titleField: 'descricao',
            outlineAlpha: 0.4,
            depth3D: 15,
            balloonText: `
              <span style='font-size:14px'><b>[[title]]</b></span><br>
              Volume: <span style='font-size:14px'><b>[[value]]</b> ([[percents]]%)</span><br>
              Valor: <span style='font-size:14px'><b>R$ [[valor]]</b>
            `,
            angle: 30,
            precision: 2,
            decimalSeparator: ',',
            thousandsSeparator: '.',
            colorField: 'color',
            labelText: '[[percents]]%',
          };
          this.loading = true;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    ajustaDados(dados) {
      dados.map((item, index) => {
        item.color = this.colors[index % this.colors.length];
        item.valor = this.getMoney(item.valor);
        return item;
      });
    },
    getMoney(str) {
      str = convertFloatToMoney(String(str));
      str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const money = str.split('.');
      return `${money[0].replace(/,/g, '.')},${money[1]}`;
    },
  },
};
</script>

<template>
  <div :id="id" :name="nome" :style="`width: 100%; height: 100%; min-height: 500px; max-height: 800px; min-width: 200px; margin-bottom: 2px`"></div>
</template>

<script>
import loadChart from '../../../produto/shared-components/chart/loadChart';

export default {
  name: 'GraficoBarras',
  props: {
    categoryField: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    sortColumns: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Array,
    },
    valueFields: {
      type: Array,
      required: true,
    },
    dados: {
      type: Array,
      required: true,
    },
    unitYAxis: {
      type: String,
      default: '',
    },
    infoYAxis: {
      type: String,
      default: '',
    },
    qtdColunasLegenda: {
      type: Number,
      default: 10,
    },
    tipoBarraRanking: {
      type: Boolean,
      default: false,
    },
    nome: {
      type: String,
      default: 'grafico_barras',
    },
    idGrafico: {
      type: String,
      default: 'bar',
    },
    titleRodape: {
      type: String,
      default: '',
    },
    labelRotation: {
      type: Boolean,
      default: false,
    },

  },
  watch: {
    dados() {
      this.montarGrafico();
    },
  },
  data() {
    return {
      isReady: false,
      barchart3dconf: {
        theme: 'light',
        type: 'serial',
        sortColumns: false,
        gridAboveGraphs: false,
        startDuration: 1,
        graphs: [],
        plotAreaFillAlphas: 0.1,
        depth3D: 12,
        angle: 55,
        autoResize: true,
        minMarginLeft: 50,
        rotate: false,
        categoryAxis: {
          gridPosition: 'start',
          gridAlpha: 0,
          tickPosition: 'start',
          tickLength: 20,
          labelRotation: this.labelRotation ? 15 : 0,
          title: this.titleRodape,
        },
        titles: [{
          text: this.title,
          size: 24,
        }],
        legend: {
          enabled: !this.tipoBarraRanking,
          maxColumns: this.qtdColunasLegenda || 10,
          align: 'center',
          useGraphSettings: true,
          position: 'bottom',
        },
        valueAxes: [],
      },
      id: this.idGrafico,
      chartTitle: '',
    };
  },
  methods: {
    montarGrafico() {
      if (typeof AmCharts === 'undefined') return;
      this.barchart3dconf.export = {
        enabled: !!(this.dados && this.dados.length > 0),
        pageOrigin: false,
        menu: [{
          class: 'export-main',
          menu: ['JPG', 'PNG', 'PDF'],
        }],
      };
      this.barchart3dconf.categoryField = this.categoryField;
      this.barchart3dconf.export.fileName = this.title !== '' ? this.title : this.chartTitle;
      this.barchart3dconf.sortColumns = this.sortColumns;
      this.barchart3dconf.columnSpacing = 6;
      this.barchart3dconf.colors = this.colors;
      this.barchart3dconf.tipoBarraRanking = this.tipoBarraRanking;
      this.barchart3dconf.valueAxes.push({
        gridColor: '#90A4AE',
        unit: this.unitYAxis,
        gridAlpha: 0.5,
        axisAlpha: 0,
        dashLength: 1,
        unitPosition: 'left',
        position: 'left',
        title: this.infoYAxis,
      });
      this.barchart3dconf.graphs = [];
      this.valueFields.forEach((el) => {
        if (!this.tipoBarraRanking) {
          this.barchart3dconf.graphs.push(this.getGraficoBarra(el));
        } else {
          this.barchart3dconf.graphs.push(this.getGraficoBarraRanking(el));
        }
      });

      if (!this.tipoBarraRanking) {
        this.barchart3dconf.numberFormatter = {
          precision: 2,
          decimalSeparator: ',',
          thousandsSeparator: '.',
        };
      }

      this.barchart3dconf.dataProvider = this.dados || [];
      AmCharts.addInitHandler((chart) => {
        if (chart.tipoBarraRanking === false) {
          return;
        }

        if (!chart.graphs) {
          return;
        }

        chart.categoryAxis.labelRotation = chart.dataProvider.length > 5 ? 45 : 0;

        chart.graphs.forEach((value, index) => {
          const graph = chart.graphs[index];
          const colorKey = `autoColor-${index}`;
          graph.lineColorField = colorKey;
          graph.fillColorsField = colorKey;

          chart.dataProvider.forEach((valor, i) => {
            const color = chart.colors[i];
            chart.dataProvider[i][colorKey] = color;
          });
        });
      }, ['serial']);

      this.barchart3dconf.zoomOutText = '';
      this.barchart3dconf.maxSelectedSeries = 12;
      this.barchart3dconf.columnWidth = 1;

      this.barchart3dconf.chartScrollbar = {
        updateOnReleaseOnly: true,
        enabled: this.dados && this.dados.length > 12,
      };
      AmCharts.makeChart(this.id, this.barchart3dconf);
    },
    getGraficoBarra(el) {
      return {
        balloonText: `${el.balloonText}`,
        fillAlphas: 0.8,
        lineAlpha: 0.2,
        title: `${el.title}`,
        type: 'column',
        valueField: el.valueField,
        precision: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
      };
    },
    getGraficoBarraRanking(el) {
      return {
        balloonText: `${el.balloonText}`,
        labelText: '[[value]]',
        labelPosition: 'top',
        fontSize: 14,
        padding: '10px',
        fillAlphas: 0.8,
        lineAlpha: 0.2,
        title: `${el.title}`,
        type: 'column',
        valueField: el.valueField,
      };
    },
  },
  mounted() {
    window.addEventListener('AmChartCarregado', () => {
      this.isReady = true;
    });
  },
  beforeMount() {
    loadChart().then(() => {
      window.dispatchEvent(new Event('AmChartCarregado'));
      this.montarGrafico();
    });
  },
};
</script>

<template>
  <div :id="id" :style="`width: 100%; height: 100%; min-height: 290px; max-height: 290px; min-width: 200px;}`"></div>
</template>

<script>
import loadChart from '../../../produto/shared-components/chart/loadChart';

export default {
  name: 'GraficoPizza',
  props: {
    id: {
      type: String,
      required: true,
    },
    categoryField: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    valueFields: {
      type: Object,
      required: true,
    },
    dados: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dados() {
      this.montarGrafico();
    },
  },
  data() {
    return {
      isReady: false,
    };
  },
  methods: {
    montarGrafico() {
      if (typeof AmCharts === 'undefined') return;

      const pie = {
        id: `pie-chart-${new Date().getMilliseconds()}`,
        type: 'pie',
        titles: [{
          text: this.title,
          size: 24,
        }],
        theme: 'none',
        dataProvider: this.dados,
        ...this.valueFields,
        legend: {
          position: 'right',
          markerType: 'circle',
          labelText: '[[title]]',
          valueText: '',
        },
        export: {
          enabled: !!(this.dados && this.dados.length > 0),
          fileName: this.title,
          pageOrigin: false,
          menu: [{
            class: 'export-main',
            menu: ['JPG', 'PNG', 'PDF'],
          }],
        },
        colors: ['#4285f4', '#ff9901'],
      };

      AmCharts.makeChart(this.id, pie);
    },
  },
  mounted() {
    window.addEventListener('AmChartCarregado', () => {
      this.isReady = true;
    });
  },
  beforeMount() {
    loadChart().then(() => {
      window.dispatchEvent(new Event('AmChartCarregado'));
      this.montarGrafico();
    });
  },
};
</script>

<template>
  <v-card :elevation="6" class="mx-auto pa-6 mr-2" >
    <grafico-barras
    :title="$tc('label.dash_planejamento_oi', 1)"
    :idGrafico="'investido_oi'"
    :category-field="`mesAno`"
    :qtdColunasLegenda="qtdColunasLegenda"
    :value-fields="valueFields"
    :dados="dados"
    :colors="colors"
    :titleRodape="$tc('label.mes', 1)"
    :unit-y-axis="`${$t('label.unidade_dinheiro')} `"/>
  </v-card>
</template>
<script>

import { buscarDadosGraficoInvestidoPorOi } from '../../../../common/resources/akzo-dashboard-sustentabilidade';
import GraficoBarras from '../../graficos/GraficoBarras';

export default {
  name: 'GraficoBarrasInvestidoPorOi',
  props: {
    filtro: {
      type: Object,
    },
  },
  watch: {
    filtro: {
      handler() {
        this.filtroAplicado = this.filtro;
        this.buscarDadosGrafico();
      },
    },
  },
  computed: {
    filtrosFixosClass() {
    },
    isDadosGrafico() {
      return this.dados && this.dados.length > 0;
    },
  },
  components: {
    GraficoBarras,
  },
  data() {
    return {
      dados: [],
      valueFields: [],
      listValueFields: [
        'investidoTintaSlob',
        'investidoTintaLinha',
      ],
      qtdColunasLegenda: 0,
      loading: false,
      filtroAplicado: this.filtro,
      colors: ['#4285f4', '#ff9901'],
    };
  },
  methods: {
    buscarDadosGrafico() {
      buscarDadosGraficoInvestidoPorOi(this.filtroAplicado, this.$resource)
        .then((response) => {
          this.dados = response.data.dados;
          this.qtdColunasLegenda = this.valueFields.length;
          this.valueFields = [];

          this.listValueFields.forEach((v) => {
            const label = this.getLabel(v);

            if (label) {
              const item = {
                balloonText: `${this.getLabel(v)} : ${this.$t('label.unidade_dinheiro')} [[value]]`,
                title: this.getLabel(v),
                valueField: v,
                type: 'column',
              };

              this.valueFields.push(item);
            }
          });
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    getLabel(v) {
      return v === 'investidoTintaLinha' ? this.$t('label.tintas_linha') : this.$t('label.tintas_slob');
    },
  },
};
</script>

<template>
  <div>
    <dashboard-pizza-painel-sustentabilidade />
    <dashboard-barra-painel-sustentabilidade />
  </div>
</template>
<script>

import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import DashboardPizzaPainelSustentabilidade from './DashboardPizzaPainelSustentabilidade';
import DashboardBarraPainelSustentabilidade from './DashboardBarraPainelSustentabilidade';
import GraficoPizza from '../graficos/GraficoPizza';

export default {
  name: 'RelatorioPainelSustentabilidade',
  components: {
    MetadadosContainerLayout,
    DashboardPizzaPainelSustentabilidade,
    DashboardBarraPainelSustentabilidade,
    GraficoPizza,
  },
};
</script>
<style>
</style>

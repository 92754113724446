<template>
  <v-card :elevation="6" class="mx-auto pa-6 mr-2" >
    <grafico-barras
    :title="$tc('label.dash_projeto_vs_avulsa', 1)"
    :idGrafico="'projeto_doacoes'"
    :category-field="`mesAno`"
    :qtdColunasLegenda="qtdColunasLegenda"
    :value-fields="valueFields"
    :dados="dados"
    :colors="colors"
    :titleRodape="$tc('label.mes', 1)"
    :unit-y-axis="`${$t('label.unidade_dinheiro')} `"/>
  </v-card>
</template>
<script>

import { buscarDadosGraficoProjetoDoacoesAvulsas } from '../../../../common/resources/akzo-dashboard-sustentabilidade';
import GraficoBarras from '../../graficos/GraficoBarras';

export default {
  name: 'GraficoBarrasProjetosDoacoesAvulsas',
  props: {
    filtro: {
      type: Object,
    },
  },
  watch: {
    filtro: {
      handler() {
        this.filtroAplicado = this.filtro;
        this.buscarDadosGrafico();
      },
    },
  },
  computed: {
    filtrosFixosClass() {
    },
    isDadosGrafico() {
      return this.dados && this.dados.length > 0;
    },
  },
  components: {
    GraficoBarras,
  },
  data() {
    return {
      dados: [],
      valueFields: [],
      listValueFields: [
        'projetos',
        'doacoesAvulsas',
      ],
      qtdColunasLegenda: 0,
      loading: false,
      filtroAplicado: this.filtro,
      colors: ['#4285f4', '#ff9901'],
    };
  },
  methods: {
    buscarDadosGrafico() {
      buscarDadosGraficoProjetoDoacoesAvulsas(this.filtroAplicado, this.$resource)
        .then((response) => {
          this.dados = response.data.dados;
          this.qtdColunasLegenda = this.valueFields.length;
          this.valueFields = this.listValueFields.map((v) => {
            const item = {
              balloonText: `${this.getLabel(v)} : ${this.$t('label.unidade_dinheiro')} [[value]]`,
              title: this.getLabel(v),
              valueField: v,
              type: 'column',
            };
            return item;
          });
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    capitalize(str) {
      if (typeof str !== 'string') {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.substr(1);
    },
    getLabel(v) {
      return v === 'projetos' ? this.capitalize(v) : this.$t('label.dash_doacoes_avulsa');
    },
  },
};
</script>

<template lang="html">
  <v-tabs
    v-model="tabSelecionada"
    class="relatorio-list__tabs"
    grow
    color="accent"
    background-color="rgba(0,0,0,0)">

    <v-tab class="white-text" href="#tab-relatorio-exportacoes" v-if="acessoExportacaoes">
      {{ $t('label.exportacoes') }}
    </v-tab>

    <v-tab class="white-text" href="#tab-painel-sustentabilidade" v-if="acessoPainelSustentabilidade">
      {{ $t('label.painel_sustentabilidade') }}
    </v-tab>

    <v-tabs-items v-model="tabSelecionada">
      <v-tab-item id="tab-relatorio-exportacoes">
        <relatorio-exportacoes ref="tab-relatorio-exportacoes"/>
      </v-tab-item>

      <v-tab-item id="tab-painel-sustentabilidade">
        <relatorio-exportacao-painel-sustentabilidade />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import RelatorioExportacoes from '@/produto/spa/relatorios/exportacoes/RelatoriosExportacoes';
import RelatorioExportacaoPainelSustentabilidade from './exportacoes/RelatorioExportacaoPainelSustentabilidade';

export default {
  data() {
    return {
      tabSelecionada: null,
      permissoesRelatorios: ['REL_ACAO_PGTO', 'REL_PROVISAO', 'REL_SALDO', 'REL_STATUS_ACAO',
        'REL_HOLDING_BLOQ', 'REL_ACOES_DE_CONTRATO', 'REL_CONT_AP', 'REL_DESC_AP', 'REL_PROJ_SUST',
        'REL_KPIS_SUST', 'REL_ACOES_SUST', 'REL_TIME_LEAD_SUST',
      ],
    };
  },
  components: {
    RelatorioExportacoes,
    RelatorioExportacaoPainelSustentabilidade,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acessoExportacaoes() {
      return this.permissoesRelatorios.filter((permissoes) => this.getAllRoles.filter((el) => el.indexOf(permissoes) !== -1).length > 0).length > 0;
    },
    acessoPainelSustentabilidade() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_PAINEL_SUST') !== -1).length > 0;
    },
  },
};
</script>

<style lang="scss">
.relatorio-list__tabs a.v-tab {
  color: var(--v-accent) !important;
}
.relatorio-list__tabs .v-tabs-items {
  background: none;
}
.relatorio-list__tabs div.v-slide-group__wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

</style>
